export default {
  // "download.locales":"",
  "download.locales.title":"威思客雲",
  "download.locales.Scan":"掃一掃二維碼，下載最新威思客雲客戶端",
  "download.locales.iPad":"還可以用iPad打開App Store， 搜索 「威思客雲」，點擊下載",
  "download.locales.Applet":"小程序",

  "download.locales.clickdown":"點擊下載",
  "download.locales.title1":"威思客雲APP",
  "download.locales.visitor.mini":"訪客小程序",
  "download.locales.toilet.mini":"廁位小程序",
}
