/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable global-require */
import React, { useEffect } from 'react';
import { history, connect, getLocale } from 'umi';
import LightSpeed from 'react-reveal/Fade';
import Slide from 'react-reveal/Fade';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import ContactUs from '@/components/ContactUs';
import Partner from '@/components/Partner';
import banner from '@/assets/home/banner.png';
import bannerEn from '@/assets/home/bannerEn.png';
import hero from '@/assets/home/hero.png';
import part1bg from '@/assets/home/part1bg.png';
import vst from '@/assets/home/vst.png';
import smt from '@/assets/home/smt.png';
import sws from '@/assets/home/sws.png';
import fm from '@/assets/home/fm.png';
import esg from '@/assets/home/esg.png';
import bdp from '@/assets/home/bdp.png';
import zhuanzhu from '@/assets/home/zhuanzhu.png';
import anquan from '@/assets/home/anquan.png';
import chengben from '@/assets/home/chengben.png';
import fuwu from '@/assets/home/fuwu.png';
import bg from '@/assets/home/bg.png';
import styles from './index.less';
import { zhIntl } from '@/utils/intlUtils';

const part1Data = [
  {
    name: zhIntl('智能访客'),
    desc: zhIntl('智能，安全的场景化云服务，助力访客体验升级'),
    icon: vst,
    url: '',
    background: '#F7F9FB',
    key: 'vst',
  },
  {
    name: zhIntl('智能会议'),
    desc: zhIntl('随时随地预约会议资源，带给客户智能高效的会议体验'),
    icon: smt,
    url: '',
    background: '#FFF',
    key: 'smt',
  },
  {
    name: zhIntl('空间管理'),
    desc: zhIntl('可视化IOT集成平台，一站式智能化整体解决方案'),
    icon: sws,
    url: '',
    background: '#FFF',
    key: 'sws',
  },
  {
    name: zhIntl('设备设施管理'),
    desc: zhIntl('线上化、流程化、数据化、智能化、智慧化的建筑设施综合运维管理平台'),
    icon: fm,
    url: '',
    background: '#F7F9FB',
    key: 'fm',
  },
  {
    name: zhIntl('环境能碳管理'),
    desc: zhIntl(
      '以"碳中和，碳达峰"为目标，通过对多种设备进行综合监管，以数字化手段为企业节能、增效',
    ),
    icon: esg,
    url: '',
    background: '#F7F9FB',
    key: 'esg',
  },
  {
    name: zhIntl('企业数字洞察'),
    desc: zhIntl('一站式智能数据平台，以大数据驱动企业决策，优化资源，提升效率'),
    icon: bdp,
    url: '',
    background: '#F7F9FB',
    key: 'bdp',
  },
];

const part2Data = [
  {
    title: zhIntl('产品更专注'),
    desc: zhIntl('十余年专注办公场景，千余家企业信任，助力企业智能办公'),
    icon: zhuanzhu,
    key: 'zhuanzhu',
  },
  {
    title: zhIntl('数据更安全'),
    desc: zhIntl('威思客云携手阿里云，提供严格的数据安全服务，为您的数据隐私和安全提供保障'),
    icon: anquan,
    key: 'anquan',
  },
  {
    title: zhIntl('企业成本更低'),
    desc: zhIntl('按需购买，即买即用，不再需要购买服务器，也不用考虑安装和维护，系统自动升级'),
    icon: chengben,
    key: 'chengben',
  },
  {
    title: zhIntl('服务更放心'),
    desc: zhIntl('提供及时专业的技术支持，包括在线客服、在线反馈，1V1顾问式服务'),
    icon: fuwu,
    key: 'fuwu',
  },
];

const Page = () => {
  const headListener = () => {
    const header = document.getElementById('head');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (header) {
      if (scrollTop === 0) {
        header.style.top = '0px';
        header.style.boxShadow = 'none';
      }
      if (scrollTop > 0) {
        header.style.top = `-${header.offsetHeight}px`;
      }
      if (scrollTop > 300) {
        header.style.top = '0px';
        header.style.boxShadow =
          'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', () => headListener());
    return document.removeEventListener('scroll', headListener);
  }, []);

  return (
    <div className={styles.home}>
      <div id="head" className={styles.header}>
        <Header type="home" />
      </div>

      <img src={bg} className={styles.bg} alt="" />
      <div className={styles.label}>
        <Slide bottom cascade>
          <div className={styles.title}>
            <div>{zhIntl('使用全球广受好评的')}</div>
            <div>{zhIntl('威思客云平台')}</div>
          </div>
        </Slide>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>
            {zhIntl('让办公变得更智慧、更智能，用更少的时间获得更高的效率')}
          </div>
        </LightSpeed>
      </div>
      <div className={styles.show}>
        <img className={styles.banner} src={getLocale() === 'en-US' ? bannerEn : banner} alt="" />
        <img className={styles.hero} src={hero} alt="" />
      </div>
      {/* 产品介绍 */}
      <div className={styles.part1}>
        <div className={styles.left}>
          <img src={part1bg} alt="" />
          <div className={styles.words}>
            <div className={styles.title}>{zhIntl('威思客云')}，</div>
            <div className={styles.title}>
              <span>{zhIntl('为')}</span>
              <span style={{ color: '#FFB26A' }}>{zhIntl('企业智慧办公')}</span>
            </div>
            <div className={styles.title}>{zhIntl('赋能')}</div>
            <div className={styles.tip} style={{ marginTop: 30 }}>
              {zhIntl(
                '致力于将企业的办公场景的全过程数字化、智能化，构建云时代的办公场景，助力高效办公。',
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          {part1Data.map((item) => (
            <div className={styles.product} key={item.key}>
              <div className={styles.productInfo}>
                <div className={styles.productName}>
                  <img src={item.icon} width={80} alt="" />
                  <div>{item.name}</div>
                </div>
                <div className={styles.productDesc}>{item.desc}</div>
              </div>
              <a className={styles.productBtn} onClick={() => history.push('/register')}>{`${zhIntl(
                '立即体验',
              )}>`}</a>
            </div>
          ))}
        </div>
      </div>
      {/* 我们的优势 */}
      <div className={styles.part2}>
        <div className={styles.title}>{zhIntl('我们的优势')}</div>
        <div className={styles.advantageList}>
          {part2Data.map((item) => (
            <div className={styles.advantageItem} key={item.key}>
              <img src={item.icon} />
              <div>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.desc}>{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* 合作伙伴 */}
      <Partner />
      {/* 联系我们 */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default connect((state) => state)(Page);
