export default {
  // 'product.introduc': '',
  'product.Intelligent.Cloud.visitor.platform': '智能化的雲訪客平台',
  'product.Intelligent.meet.visitor.platform': '智能化的雲會議平台',
  'product.introduc.Cloud.visitor.advantage1': '多種登錄方式',
  'product.introduc.Cloud.visitor.advantage1.desc':
    '微信小程序，瀏覽器，APP，隨時隨地處理訪客預約，訪客邀請業務。',
  'product.introduc.Cloud.visitor.advantage2': '個性化模版',
  'product.introduc.Cloud.visitor.advantage2.desc': '多種個性化訪客卡模版，彰顯企業風采。',
  'product.introduc.Cloud.visitor.advantage3': '全方位提醒',
  'product.introduc.Cloud.visitor.advantage3.desc':
    '集成短訊，郵件等多種方式訪客預約，訪客到訪提醒，不再錯過重要客戶。',
  'product.introduc.Cloud.visitor.advantage4': '證件識別',
  'product.introduc.Cloud.visitor.advantage4.desc':
    '自動識別身份證件，大幅度提高等級效率，保證信息準確性。',
  'product.introduc.Cloud.visitor.advantage5': '安全審批流程',
  'product.introduc.Cloud.visitor.advantage5.desc': '鑒別訪客身份，提高訪問的安全性。',
  'product.introduc.Cloud.visitor.advantage6': '團體邀請管理',
  'product.introduc.Cloud.visitor.advantage6.desc':
    '自動生成邀請鏈接，滿足人員較多的會議，活動等場景需求。',
  'product.introduc.Cloud.visitor.advantage7': '數據分析展示',
  'product.introduc.Cloud.visitor.advantage7.desc':
    '全面透視邀請，到訪結果，靈活查詢報表，助力決策。',
  'product.introduc.Cloud.visitor.advantage8': '黑名單管理',
  'product.introduc.Cloud.visitor.advantage8.desc': '有效限制不良來訪，為企業安全保駕護航。',
  'product.introduc.Cloud.meet.subTitle1': '多種登錄方式',
  'product.introduc.Cloud.meet.subTitle1.desc': `智能會議系統具備會議預約、審批、通知、簽到、服務、展板和報表統計等全生命周期的會議管理能力，通過AIOT軟硬件協作提供：人體感應、環境感知、設備聯動、語音交互、人臉識別等全方位的智能會議室解決方案，並與Zoom/Cisco Webex等協作會議系統集成，全方位覆蓋線上和線下會議全場景。`,
  'product.introduc.Cloud.meet.subTitle2': '多種會議預約方式',
  'product.introduc.Cloud.meet.subTitle2.desc': `PC端，app端，以及微信小程序、企業微信、釘釘的集成，通過多種方式方便您快捷預約會議，隨時隨地掌握會議狀態。`,
  'product.introduc.Cloud.meet.subTitle3': '智能感知',
  'product.introduc.Cloud.meet.subTitle3.desc': `多種iot智能硬件的全範圍監控，實現人體感應、環境感知、設備聯動等會議智慧化。`,
  'product.introduc.Cloud.meet.subTitle4': '多種集成擴展應用',
  'product.introduc.Cloud.meet.subTitle4.desc': `Exchange/Office 365集成模式，支持雙向同步和多種應用場景，如紅外傳感器互動、超長會議，周期會議、衝突模式、需審批會議、非對稱會議賬號等。`,
  'product.introduc.tabs1': '智能訪客',
  'product.introduc.visitor.subTitle1': '訪客邀約系統化',
  'product.introduc.visitor.subTitle1.desc': `便捷的邀請預約功能，零成本部署企業工作人員或外部訪客自助邀約操作，依託PC端、Pad端、移動端等無縫對接邀約需求，打造企業級雲訪客管理平台。`,
  'product.introduc.visitor.subTitle2': '身份認證渠道化',
  'product.introduc.visitor.subTitle2.desc': `安全開放的訪客身份認證理念，訪客到達前台可自主確認身份信息，企業員工可第一時間多種渠道接收訪客來訪通知，最大化貼合企業的訪客管理需求。`,
  'product.introduc.visitor.subTitle3': '數據統計信息化',
  'product.introduc.visitor.subTitle3.desc': `穩定的雲服務後台支持，滿足企業數據化統計訪客預約、簽入/簽出、身份信息等相關記錄的需求。具備多字段高效查找、信息統計易操作、雲端存儲大容量等優勢，解決企業訪客環境中的數據管理難題。`,
  'product.introduc.visitor.subTitle4': '訪客接待高時效',
  'product.introduc.visitor.subTitle4.desc': `智能高效的運維體系，全面貫穿訪客卡打印、二維碼簽入簽出、人臉拍照等訪客接待流程，配合現代化設備配置，使得訪客接待智能化並且流程靈活便捷，滿足企業、園區、樓宇等多種場景。`,
  'product.introduc.tabs2': '智能會議',
  'product.introduc.tabs3': '空間管理',
  'product.introduc.Cloud.visitor': '威思客雲訪客',
  'product.introduc.Cloud.visitor.sub1': '洞見現代訪客場景，聚焦智能企業未來',
  'product.introduc.Cloud.visitor.sub2': '全新的平台運維規劃，穩定的雲服務支持力',
  'product.introduc.Cloud.visitor.sub3': '高效的訪客接待流程',
  'product.introduc.meet.visitor': '威思客雲會議',
  'product.introduc.meet.visitor.sub1': '全場景、全生命周期的會議解決方案',
  'product.introduc.meet.visitor.sub2': '為企業帶來智能、高效、簡單、輕鬆的',
  'product.introduc.meet.visitor.sub3': '新型會議室預訂管理體驗',
  'product.introduc.header.visitor.btn1': '觀看視頻',
  'product.introduc.header.visitor.btn2': '免費體驗',
  'product.introduc.meet.visitor.advantage1': '多種預約方式',
  'product.introduc.meet.visitor.advantage1.desc':
    '微信小程序、企業微信、釘釘、App、Web瀏覽器等，隨時隨地預約和管理會議。',
  'product.introduc.meet.visitor.advantage2': '會議展板',
  'product.introduc.meet.visitor.advantage2.desc':
    '多種規格的可選硬件，多種風格的可選模板，並支持自動開關機、遠程監控、故障告警等功能。',
  'product.introduc.meet.visitor.advantage3': '簽到及消息通知',
  'product.introduc.meet.visitor.advantage3.desc':
    '短訊、郵件、微信、釘釘等多種消息通知方式可選，參會人到達會場後，可以在會議展板上通過賬號密碼、掃碼、人臉識別等多種方式簽到。',
  'product.introduc.meet.visitor.advantage4': '會議服務',
  'product.introduc.meet.visitor.advantage4.desc':
    '管理員定義會議服務套餐，預訂人會前和會中申請會議服務，並支持會議服務費用和會議室使用費用計費和統計等。',
  'product.introduc.meet.visitor.advantage5': 'Collaboration Meeting集成',
  'product.introduc.meet.visitor.advantage5.desc':
    '支持預約和管理Zoom、WebEx等網絡視頻會議，和線上線下會議室混合預約，並通過集成Zoom Rooms互動大屏。',
  'product.introduc.meet.visitor.advantage6': 'Exchange/Office365集成',
  'product.introduc.meet.visitor.advantage6.desc':
    '與Exchange或Office 365集成，實現會議數據雙向同步。',
  'product.introduc.meet.visitor.advantage7': '智能硬件聯動',
  'product.introduc.meet.visitor.advantage7.desc':
    '通過智能硬件，實現人體感應、環境感知、設備聯動、語音交互、人臉識別等智慧聯動。',
  'product.introduc.meet.visitor.advantage8': '空間狀態感應器聯動',
  'product.introduc.meet.visitor.advantage8.desc':
    '基於紅外智能感應器實時監測數據，實現會議自動簽到以及無人自動釋放會議室等。',

  'product.introduc.fm.subTitle1': '信息化',
  'product.introduc.fm.subTitle1.desc':
    '建立信息化管理平台，优化管理流程，提升工作效率，助力降低运营成本',
  'product.introduc.fm.subTitle2': '流程化',
  'product.introduc.fm.subTitle2.desc':
    '固定标准化业务流程，增强协同合作，实现系统统一管理，增强管理透明度',
  'product.introduc.fm.subTitle3': '智能化',
  'product.introduc.fm.subTitle3.desc':
    '集成智能硬件设备，形成统一管控平台，实现万物互联的智能化管控',
  'product.introduc.fm.subTitle4': '智慧化',
  'product.introduc.fm.subTitle4.desc':
    '结合Al人工智能、数字孪生等技术，使楼宇自我感知，增强用户无感体验',

  'product.introduc.fm.advantage1': '項目管理',
  'product.introduc.fm.advantage1.desc':
    '企業總部項目制、站點制獨立運行，提高項目運行效率，提升集團統壹管理',
  'product.introduc.fm.advantage2': '設備資産',
  'product.introduc.fm.advantage2.desc':
    '統壹設備資産管理平台，實現設備運行監控管理，有效延長設備運行周期',
  'product.introduc.fm.advantage3': '服務中心',
  'product.introduc.fm.advantage3.desc': '樓宇用戶服務請求統壹入口管理，提升樓宇運維管理能力',
  'product.introduc.fm.advantage4': '工單管理',
  'product.introduc.fm.advantage4.desc':
    '完整的工作流引擎自配置應用，通過靈活的配置引擎完美匹配應用場景',
  'product.introduc.fm.advantage5': '巡檢管理',
  'product.introduc.fm.advantage5.desc':
    '移動端數字化線上巡檢，實現巡檢管理實時進度在線管理，數字化管控',
  'product.introduc.fm.advantage6': '預防性維護管理',
  'product.introduc.fm.advantage6.desc':
    '日常維護計劃統壹管理平台實現線下維護工作線上化，並進行維護任務可視化應用及管理',
  'product.introduc.fm.advantage7': '知識庫管理',
  'product.introduc.fm.advantage7.desc':
    '針對不同類型的設備進行故障定義，通過數據積累建立故障原因和處理方法',
  'product.introduc.fm.advantage8': '在崗管理',
  'product.introduc.fm.advantage8.desc':
    '采用WiFi、GPS定位、藍牙等技術進行在線簽到，並實時同步員工在崗情況',

  'producr.introduc.fm.equipment.ledger': '設備台賬',
  'producr.introduc.fm.inventory.management': '庫存管理',
  'producr.introduc.fm.contract.management': '合同管理',
  'producr.introduc.fm.supplier.management': '供應商管理',

  'product.introduc.esg.tip1': '環境能碳管理系統',
  'product.introduc.esg.tip2':
    '廣泛快速的自動采集，精准分析和智能優化，推進企業數字化轉型，促進企業ESG目標，響應國家雙碳戰略',
  'product.introduc.esg.subTitle1': '綜合能源環境自動采集',
  'product.introduc.esg.subTitle1.desc': `可實現園區樓宇的水、電、氣、暖、環境、光伏、儲能、充電樁等綜合能源數據快速接入，對實時能源&碳排放數據監測，電力采集使用無線無源裝置，免停電，對正常運營零影響零損失，環境監測執行Well標准，監測溫濕度、PM2.5、PM10、TVOC、二氧化碳等數據，並配有相關標准解讀。`,
  'product.introduc.esg.subTitle2': '南北向系統設備廣泛接入',
  'product.introduc.esg.subTitle2.desc': `平台API，采用雲雲對接，邊雲對接；邊緣API，三方硬件接入、三方服務接入、邊緣數據分析、三方集成文檔、數據推送API、Kafka、Rabbitmq、Mqtt、Azure Servicebus、AWS SQS、PUBSUB。`,
  'product.introduc.esg.subTitle3': '智能調控，流程閉環',
  'product.introduc.esg.subTitle3.desc': `根據園區樓宇場景化數據分析診斷、系統分析和精准調控形成樓宇智能化監管閉環，以場景化控制策略，替代傳統單設備控制操作，有效通過動靜、日光等感知器件，實現智能化控制；通過會議系統集成、時間定時管理、遠程控制管理、BMS系統集成、中央空調節能控制、智能照明控制、環境智能管理、遠程/定時/計劃控制等，提高能源調控水平。`,
  'product.introduc.esg.subTitle4': '多維度的可視化數據展示',
  'product.introduc.esg.subTitle4.desc': `采用GIS、BIM、CIM、3D/2D集成展示園區樓宇數據，能源、碳、環境的實時數據、曆史數據，同比、環比等，同時具備大屏、信發、PC/APP多端展示及互動功能，運行鍵康參數壹目了然。`,
  'product.introduc.Cloud.esg.advantage1': '助力企業ESG戰略',
  'product.introduc.Cloud.esg.advantage1.desc':
    '幫助公司管理能源使用及消耗，識別並解決能源浪費問題，更好地應對投資者和其他利益相關者的關切，減少對環境的負面影響，爲公司贏得更好的社會聲譽',
  'product.introduc.Cloud.esg.advantage2': '促進數字化轉型',
  'product.introduc.Cloud.esg.advantage2.desc':
    '采用物聯網、雲計算、大數據、AI、5G等信息化技術對園區樓宇綜合能源、碳排、環境實現全生命周期動態監測，實現社會責任與企業發展、節能減碳與增産增效的雙平衡',
  'product.introduc.Cloud.esg.advantage3': '洞察分析優化',
  'product.introduc.Cloud.esg.advantage3.desc':
    '通過園區樓宇的能耗數據監管，碳排放換算，能耗預測分析，使管理者全面及時掌握能耗使用情況，適時進行節能調控，提高能效管理水平，最終達到開源節流，降本增效的目的',
  'product.introduc.Cloud.esg.advantage4': '高效全景可視',
  'product.introduc.Cloud.esg.advantage4.desc':
    '環境能碳管控平台的建設，基于GIS、BIM、CIM、3D/2D等可視化技術，全景呈現企業能源碳環境運營詳情，在企業發展進程中深刻踐行綠色發展、低碳發展理念',

  'product.introduc.bdp.tip1': '威思客企業數字洞察',
  'product.introduc.bdp.tip2':
    '完美融合企業行政與智能物聯大數據，通過信息發布、互動大屏和數據洞察，實現空間的可視化展示與智能信息發布。助力企業優化空間體驗，提升資源利用效率，支持企業科學決策',
  'product.introduc.bdp.subTitle1': '總裁駕駛艙',
  'product.introduc.bdp.subTitle1.desc':
    '爲高層管理團隊量身定制，整合企業數字行政與智能物聯大數據，提供深度數據清洗與優化。全面展示財務、運營、客戶、環境等績效指標以及健康關懷數據分析，通過全局數據總覽和趨勢預測，爲企業決策提供堅實支撐。',
  'product.introduc.bdp.subTitle2': '報表管理與授權',
  'product.introduc.bdp.subTitle2.desc':
    '提供壹系列專業價值報表，用戶可根據實際需求選擇並適配企業數據，快速獲取深入見解。同時支持Power BI報表快速導入，實現靈活分類管理和角色權限控制。',
  'product.introduc.bdp.subTitle3': '綜合信息發布系統',
  'product.introduc.bdp.subTitle3.desc':
    '支持視頻、圖片、PDF等多樣化媒體形式，系統實時推送報表數據至大屏端展示，簡易配置即可實現多屏內容並存及自動切換。與威思客産品深度集成，提供精細化權限管理，滿足複雜場景需求。',
  'product.introduc.bdp.subTitle4': '互動式空間資源大屏',
  'product.introduc.bdp.subTitle4.desc':
    '直觀展示空間區域的環境指數和資源狀態，提供互動式操作，如資源篩選、預約占用、地圖路徑指引等，顯著提升辦公體驗與效率。與威思客FM系統無縫對接，實現空間設備資源的高效跟蹤與維護。',
  'product.introduc.bdp.subTitle5': '互動式大屏',
  'product.introduc.bdp.subTitle5.desc':
    '支持2D/3D地圖展示，包括資源總覽和環境IAQ指標，提供可視化交互，包括資源掃碼預約、報修、路線指引。',
  'product.introduc.bdp.subTitle6': '信息發布引擎',
  'product.introduc.bdp.subTitle6.desc':
    '支持常見媒體格式，可在線編排節目，支持審核，支持插播；采用Android信發設備，支持離線播放，支持多屏播放；支持主流浏覽器。',
  'product.introduc.bdp.advantage1': '信息發布引擎',
  'product.introduc.bdp.advantage1.desc':
    '播放設備管理、播放媒體管理、播放內容編排、播放過程控制、設備離線播放、資源組劃分',
  'product.introduc.bdp.advantage2': '多屏融合技術',
  'product.introduc.bdp.advantage2.desc':
    '播放內容分屏展示、每屏內容按需配置、多屏無延遲切換、自由配置切換方式',
  'product.introduc.bdp.advantage3': '互動式大屏功能',
  'product.introduc.bdp.advantage3.desc':
    '地圖可視化展示、資源狀態總覽、地圖路徑指引、資源快速檢索、掃碼預約使用',
  'product.introduc.bdp.advantage4': '設備資源展示',
  'product.introduc.bdp.advantage4.desc':
    '設備分類篩選、IOT數據采集、IAQ環境指標、設備資源掃碼報修、FM系統對接',
  'product.introduc.bdp.advantage5': '成本統計分析',
  'product.introduc.bdp.advantage5.desc':
    '總體成本構成、成本趨勢變化、多維度結算分析、多維度TOPN分析',
  'product.introduc.bdp.advantage6': '空間資源利用優化',
  'product.introduc.bdp.advantage6.desc':
    '綜合資源利用、利用率趨勢變化、利用率分段分布、利用率排行榜、利用率區域對比',
  'product.introduc.bdp.advantage7': '綜合影響評估',
  'product.introduc.bdp.advantage7.desc': '環境與資源利用、行爲與資源利用、資源利用與能耗',
  'product.introduc.bdp.advantage8': '數字可視化展示',
  'product.introduc.bdp.advantage8.desc': '能源能耗展示、綠色低碳展示、數字資産展示、WELL環境展示',
};
