import { SECRET_KEY } from './constant';
import { PASSWORD_AES_VI } from '@/utils/constant';
import CryptoJS from 'crypto-js';

/**
 * 云服务认证加密 AES加密
 * @param data
 * @param aesKey
 * @return {string}
 */
 export const encryptAes = (data, aesKey, checkKey = true) => {
  if (!data?.trim()) {
    return '';
  }
  const aesKeyUsed = window.encryptStrength === '0' && checkKey ? PASSWORD_AES_VI : aesKey;
  const key = CryptoJS.enc.Utf8.parse(aesKeyUsed);
  const iv = CryptoJS.enc.Utf8.parse(PASSWORD_AES_VI);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  // console.log('encrypted', encrypted.toString())
  return encrypted.toString(); // 返回的是base64格式的密文
};

/**
 * 云服务认证解密 AES解密
 * @param data
 * @param aesKey
 * @return {string}
 */
 export const decryptAes = (data, aesKey) => {
  if (!data) {
    return '';
  }

  // PASSWORD_AES_VI 为偏移量，且为128加解密的秘钥，解密先用256解，未成功则用128

  /* 密钥 */
  const key = CryptoJS.enc.Utf8.parse(aesKey);

  /* 为密钥偏移量 */
  const iv = CryptoJS.enc.Utf8.parse(PASSWORD_AES_VI);

  let decrypted = '';
  let decryptedStr = '';
  try {
    decrypted = CryptoJS.AES.decrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    try {
      decrypted?.toString?.(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log('AES解密decrypted失败:', error);
      return decryptAes(data, PASSWORD_AES_VI);
    }
  } catch (error) {
    console.log('AES解密失败:', error);
    return decryptAes(data, PASSWORD_AES_VI);
  }

  try {
    decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted); // 返回的是base64格式的明文
    // 拿到明文去加密，校验可逆性
    if(data !== encryptAes(decryptedStr?.toString(), aesKey, false)){
      // 加解密不可逆，说明256解密后明文为非法字符串，再次使用128加密
      console.log('AES256解密字符串不可逆');
      return decryptAes(data, PASSWORD_AES_VI);
    }
  } catch (error) {
    console.log('AES解密toString失败:', error);
    return decryptAes(data, PASSWORD_AES_VI);
  }

  if (!decryptedStr) {
    // console.log('256失败,转128, decryptedStr： ', decryptedStr);
    return decryptAes(data, PASSWORD_AES_VI);
  }

  return decryptedStr?.toString(); // 返回的是base64格式的密文
};

// 云服务认证加密 base64编码

export const base64String = word => {
  const str = CryptoJS.enc.Utf8.parse(word);
  return CryptoJS.enc.Base64.stringify(str);
};

// 云服气签名值计算

export const getJoinUrl = (url, timestamp) => {
  var newurl = url + `&timestamp=${timestamp}`;
  var urlStr = newurl
    .split('&')
    .sort()
    .join('&');
  return urlStr + '&sign=' + getSign(urlStr);
};

export const getSign = url => {
  return CryptoJS.MD5(encodeURIComponent(url + SECRET_KEY)).toString();
};

// SSO key编解码计算
export const encodeAuth = auth => {
  return window.btoa(encodeURIComponent(JSON.stringify(auth)));
};

export const decodeAuth = key => {
  return JSON.parse(decodeURIComponent(window.atob(key)));
};
