/* eslint-disable react/no-array-index-key */
/**
 * title: 产品定价
 */
import React from 'react';
import { formatMessage, history, connect } from 'umi';
import { Tabs, Button } from 'antd';
import { useMount } from 'ahooks';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import ContactUs from '@/components/ContactUs';
import { ROUTE_MAP } from '@/utils/constant';
import { zhIntl } from '@/utils/intlUtils';
import bg from '@/assets/product/price/bg.png';
import styles from '../index.less';

const data = [
  {
    key: 'vst',
    title: formatMessage({ id: 'product.introduc.tabs1' }),
    content: [
      {
        title: formatMessage({ id: 'price.locales.Basic.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 600 : 120,
        price: 600,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          // `员工人数最高为2000`,
          'price.locales.contain.Unlimited.invitation',
          'price.locales.contain.Unrestricted.registration',
          'price.locales.contain.Email.notification',
          'price.locales.contain.SMS.notification',
          'price.locales.contain.Custom.brand',
          '--',
          '--',
          '--',
          '--',
        ],
      },
      {
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 800 : 160,
        price: 800,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.the.basic.version`,
          `price.locales.contain.Label.printing`,
          `price.locales.contain.Confidentiality.agreement`,
          `price.locales.contain.Access.type.customization`,
          `price.locales.contain.Statistical.report`,
          '--',
          '--',
          '--',
          '--',
        ],
      },
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Advanced.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 1000 : 200,
        price: 1000,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Edition`,
          `price.locales.contain.facetime`,
          `price.locales.contain.Batch.invitation`,
          `price.locales.contain.Certificate.identification`,
          `price.locales.contain.Blacklist.managemen`,
          `price.locales.contain.Group.invitation`,
          `price.locales.contain.Temporary.audit`,
          `price.locales.contain.SMS.advance.notice`,
          `price.locales.contain.Email.advance.notice`,
        ],
      },
    ],
  },
  {
    key: 'smt',
    title: formatMessage({ id: 'product.introduc.tabs2' }),
    content: [
      {
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 299 : 59,
        price: 299,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          'price.locales.contain.Display.board.function',
          `price.locales.contain.Display.Panel.monitoring`,
          `price.locales.contain.Start.the.meeting.now`,
          `price.locales.contain.Meeting.sign.in`,
          `price.locales.contain.Pre.meeting.reminder`,
          `price.locales.contain.Reminder.before.the.end.of.the.meeting`,
          `price.locales.contain.Extension.of.the.session`,
          `price.locales.contain.One.key.maintenance.conference.room`,
          `price.locales.contain.Participant.conflict.reminder`,
          `price.locales.contain.SMS.notification`,
          `price.locales.contain.Email.notification`,
          `price.locales.contain.Statistical.report`,
          '--',
          '--',
        ],
      },
      {
        title: formatMessage({ id: 'price.locales.Advanced.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 499 : 99,
        price: 499,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Edition`,
          `price.locales.contain.Conference.equipment.borrowing`,
          `price.locales.contain.Conference.room.up.and.down`,
          `price.locales.contain.Number.of.meeting.rooms`,
          `price.locales.contain.Cleaning.service`,
          `price.locales.contain.Meeting.agenda`,
          `price.locales.contain.Conference.billing`,
          `price.locales.contain.Conference.services`,
          `price.locales.contain.Appointment.cycle.meeting`,
          `price.locales.contain.Book.extra.long.meetings`,
          `price.locales.contain.Book.multi.room.meetings`,
          `price.locales.contain.Meeting.room.reservation.authority`,
          `price.locales.contain.Meeting.approval`,
          `price.locales.contain.Meeting.lock.time`,
        ],
      },
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Exclusive.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 799 : 159,
        price: 799,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Advanced`,
          `price.locales.contain.Enterprise.wechat`,
          `price.locales.contain.Nail.nail`,
          `price.locales.contain.Network.conference`,
          '--',
          '--',
          '--',
          '--',
          '--',
          '--',
          '--',
          '--',
          '--',
          '--',
        ],
      },
    ],
  },
  {
    key: 'sws',
    title: formatMessage({ id: 'product.introduc.tabs3' }),
    content: [
      {
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 100 : 20,
        price: 299,
        desc: formatMessage({ id: `price.locales.workspace.desc1` }),
        link: ROUTE_MAP.register,
        actions: [
          'price.locales.workspace.standard.include1',
          'price.locales.workspace.standard.include2',
          'price.locales.workspace.standard.include3',
          '--',
          '--',
          '--',
          '--',
          '--',
        ],
      },
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Advanced.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 500 : 100,
        price: 499,
        desc: formatMessage({ id: `price.locales.workspace.desc2` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Edition`,
          `price.locales.workspace.advanced.include1`,
          `price.locales.workspace.advanced.include2`,
          `price.locales.workspace.advanced.include3`,
          `price.locales.workspace.advanced.include4`,
          `price.locales.workspace.advanced.include5`,
          `price.locales.workspace.advanced.include6`,
          `price.locales.workspace.advanced.include7`,
        ],
      },
    ],
  },
  {
    key: 'fm',
    title: formatMessage({ id: 'index.fm' }),
    content: [
      {
        title: formatMessage({ id: 'price.locales.Basic.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 4000 : 800,
        price: 4000,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          'producr.introduc.fm.equipment.ledger',
          'product.introduc.fm.advantage4',
          'product.introduc.fm.advantage5',
          '--',
        ],
      },
      {
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 8000 : 1600,
        price: 8000,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.the.basic.version`,
          `product.introduc.fm.advantage3`,
          `product.introduc.fm.advantage6`,
          `producr.introduc.fm.inventory.management`,
        ],
      },
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Exclusive.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 12000 : 2400,
        price: 12000,
        desc: formatMessage({ id: `price.locales.Excluding.hardware.terminal` }),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Edition`,
          `producr.introduc.fm.contract.management`,
          `producr.introduc.fm.supplier.management`,
          // `product.introduc.fm.advantage7`,
          '--',
        ],
      },
    ],
  },
  {
    key: 'esg',
    title: formatMessage({ id: 'index.esg' }),
    content: [
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 999 : 199,
        price: 20,
        desc: zhIntl('50个设备起购'),
        link: ROUTE_MAP.register,
        actions: [
          'price.locales.esg.standard.include1',
          'price.locales.esg.standard.include2',
          'price.locales.esg.standard.include3',
          'price.locales.esg.standard.include4',
          'price.locales.esg.standard.include5',
          'price.locales.esg.standard.include6',
          'price.locales.esg.standard.include7',
          'price.locales.esg.standard.include8',
          'price.locales.esg.standard.include9',
          'price.locales.esg.standard.include10',
        ],
      },
    ],
  },
  {
    key: 'bdp',
    title: formatMessage({ id: 'index.bdp' }),
    content: [
      {
        title: formatMessage({ id: 'price.locales.Standard.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 999 : 199,
        price: 199,
        desc: zhIntl('信发'),
        link: ROUTE_MAP.register,
        actions: [
          'price.locales.bdp.standard.include1',
          'price.locales.bdp.standard.include2',
          'price.locales.bdp.standard.include3',
          'price.locales.bdp.standard.include4',
          'price.locales.bdp.standard.include5',
          'price.locales.bdp.standard.include6',
          'price.locales.bdp.standard.include7',
          'price.locales.bdp.standard.include8',
          'price.locales.bdp.standard.include9',
        ],
      },
      {
        recommend: true,
        title: formatMessage({ id: 'price.locales.Advanced.Edition' }),
        // price: sessionStorage.getItem('isChina') === 'true' ? 999 : 199,
        price: 299,
        desc: zhIntl('互动大屏'),
        link: ROUTE_MAP.register,
        actions: [
          `price.locales.contain.Including.all.functions.of.Standard.Edition`,
          'price.locales.bdp.advantage.include1',
          'price.locales.bdp.advantage.include2',
          'price.locales.bdp.advantage.include3',
          '--',
          '--',
          '--',
          '--',
          '--',
        ],
      },
    ],
  },
];

const Page = ({ dispatch }) => {
  useMount(() => {
    dispatch({ type: 'cloud/getSet' });
  });

  return (
    <div className={styles.product}>
      <div className={styles.bg}>
        <img src={bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>{zhIntl('产品定价')}</div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt; <span>{zhIntl('产品')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>{zhIntl('产品定价')}</span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <Fade bottom cascade>
          <div className={styles.title}>{zhIntl('选择合适的版本，开启高效办公协作')}</div>
        </Fade>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>
            {zhIntl('从个人到企业/组织，满足不同规模公司各阶段需求，为你和你的伙伴创造更多价值！')}
          </div>
        </LightSpeed>
      </div>
      <Tabs
        defaultActiveKey="vst"
        centered
        items={data.map((item) => {
          return {
            label: item.title,
            key: item.key,
            children: (
              <div className={styles.price} style={{ width: item.content?.length === 1 && 600 }}>
                {item.content?.map((it, i) => (
                  <div
                    key={it.title}
                    className={styles.priceItem}
                    style={{ background: i === 1 && '#F8F9FC' }}
                  >
                    <div className={styles.priceTitle}>
                      <div>{it.title}</div>
                      {it.recommend ? (
                        <div className={styles.recommend}>
                          <div>{zhIntl('推荐')}</div>
                        </div>
                      ) : null}
                    </div>
                    <div>{it.desc}</div>
                    <div>
                      {/* <span className={styles.money}>{`${
                        sessionStorage.getItem('isChina') === 'true' ? '￥' : '＄'
                      }${it.price}`}</span> */}
                      <span className={styles.money}>{`￥${it.price}`}</span>
                      <span className={styles.unit}>{`/${zhIntl('月')}`}</span>
                      {item?.key === 'smt' && (
                        <span className={styles.unit}>{`/${zhIntl('会议室')}`}</span>
                      )}
                      {item?.key === 'esg' && (
                        <span className={styles.unit}>{`/${zhIntl('设备')}`}</span>
                      )}
                      {item?.key === 'bdp' && (
                        <span className={styles.unit}>{`/${zhIntl('播放终端')}`}</span>
                      )}
                    </div>
                    {/* <div>{zhIntl('年付')}</div> */}
                    <Button
                      type="primary"
                      style={{
                        width: 146,
                        marginTop: 20,
                        marginBottom: 50,
                        height: 40,
                        borderRadius: 20,
                      }}
                      onClick={() => history.push('/register')}
                    >
                      {zhIntl('立即使用')}
                    </Button>
                    {it.actions.map((v, index) => (
                      <div
                        key={v}
                        className={styles.action}
                        style={{ color: i !== 0 && index === 0 && '#2FA599' }}
                      >
                        {formatMessage({ id: v })}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ),
          };
        })}
      />
      <ContactUs />

      <style jsx="true">
        {`
          .ant-tabs-tab {
            height: 36px;
            padding: 8px 27px;
            color: #000;
            font-weight: 500;
            line-height: 36px;
            background-color: #fff;
            border-radius: 18px;
          }
          .ant-tabs-tab.ant-tabs-tab-active {
            color: #fff;
            background-color: #2fa599;
          }
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #fff;
          }
          .ant-tabs-ink-bar {
            display: none;
          }
          .ant-tabs-top > .ant-tabs-nav::before,
          .ant-tabs-bottom > .ant-tabs-nav::before,
          .ant-tabs-top > div > .ant-tabs-nav::before,
          .ant-tabs-bottom > div > .ant-tabs-nav::before {
            border: none;
          }
          .ant-tabs-nav {
            margin-bottom: 60px !important;
          }
          .ant-tabs-nav-wrap {
            height: 55px;
          }
          .ant-tabs-nav-list {
            height: 50px;
            padding: 7px 15px;
            background: #fff;
            border-radius: 25px;
            box-shadow: 0px 1px 1px 0px rgba(18, 20, 32, 0.15);
          }
        `}
      </style>
    </div>
  );
};

export default connect((s) => s)(Page);
