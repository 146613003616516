/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { history, connect, formatMessage, getLocale } from 'umi';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { zhIntl } from '@/utils/intlUtils';
import check from '@/assets/bigcheck.png';
import styles from './index.less';
import { Button } from 'antd';

const productList = {
  vst: {
    productName: zhIntl('智能访客'),
    banner:
      getLocale() === 'en-US'
        ? require('@/assets/product/vst/bannerEn.png')
        : require('@/assets/product/vst/banner.png'),
    bg: require('@/assets/product/vst/bg.png'),
    title: zhIntl('威思客云访客'),
    subTitle: zhIntl(
      '洞见现代访客场景，聚焦智能企业未来，全新的平台运维规划，稳定的云服务支持，高效的访客接待流程',
    ),
    featureTitle: zhIntl('智能化的云访客平台'),
    list: [
      {
        title: formatMessage({ id: 'product.introduc.visitor.subTitle1' }),
        desc: formatMessage({ id: 'product.introduc.visitor.subTitle1.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.visitor.subTitle2' }),
        desc: formatMessage({ id: 'product.introduc.visitor.subTitle2.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.visitor.subTitle3' }),
        desc: formatMessage({ id: 'product.introduc.visitor.subTitle3.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.visitor.subTitle4' }),
        desc: formatMessage({ id: 'product.introduc.visitor.subTitle4.desc' }),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/vst/login.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage1' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage1.desc' }),
      },
      {
        icon: require('@/assets/product/vst/temp.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage2' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage2.desc' }),
      },
      {
        icon: require('@/assets/product/vst/all.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage3' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage3.desc' }),
      },
      {
        icon: require('@/assets/product/vst/card.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage4' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage4.desc' }),
      },
      {
        icon: require('@/assets/product/vst/safe.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage5' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage5.desc' }),
      },
      {
        icon: require('@/assets/product/vst/team.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage6' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage6.desc' }),
      },
      {
        icon: require('@/assets/product/vst/data.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage7' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage7.desc' }),
      },
      {
        icon: require('@/assets/product/vst/black.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage8' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.visitor.advantage8.desc' }),
      },
    ],
  },
  smt: {
    productName: zhIntl('智能会议'),
    banner:
      getLocale() === 'en-US'
        ? require('@/assets/product/smt/bannerEn.png')
        : require('@/assets/product/smt/banner.png'),
    bg: require('@/assets/product/smt/bg.png'),
    title: zhIntl('威思客云会议'),
    subTitle: zhIntl(
      '全场景、全生命周期的会议解决方案，为企业带来智能、高效、简单、轻松的新型会议室预订管理体验',
    ),
    featureTitle: zhIntl('智能化的云会议平台'),
    list: [
      {
        title: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle1' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle1.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle2' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle2.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle3' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle3.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle4' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.meet.subTitle4.desc' }),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/smt/more.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage1' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage1.desc' }),
      },
      {
        icon: require('@/assets/product/smt/pad.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage2' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage2.desc' }),
      },
      {
        icon: require('@/assets/product/smt/notice.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage3' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage3.desc' }),
      },
      {
        icon: require('@/assets/product/smt/meeting.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage4' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage4.desc' }),
      },
      {
        icon: require('@/assets/product/smt/c.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage5' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage5.desc' }),
      },
      {
        icon: require('@/assets/product/smt/365.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage6' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage6.desc' }),
      },
      {
        icon: require('@/assets/product/smt/hardware.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage7' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage7.desc' }),
      },

      {
        icon: require('@/assets/product/smt/space.png'),
        title: formatMessage({ id: 'product.introduc.meet.visitor.advantage8' }),
        desc: formatMessage({ id: 'product.introduc.meet.visitor.advantage8.desc' }),
      },
    ],
  },
  sws: {
    productName: zhIntl('空间管理'),
    banner: require('@/assets/product/sws/banner.png'),
    bg: require('@/assets/product/sws/bg.png'),
    title: zhIntl('空间管理'),
    subTitle: zhIntl(
      '助企业针对固定座位和共享座位进行精细化分类管理，配合流程平台实现自动化联动处理，简化多平台手工操作，携手智能硬件，实现基于物联网的智能楼宇空间管控',
    ),
    featureTitle: zhIntl('智能化空间管理平台'),
    list: [
      {
        title: zhIntl('数据采集与分析'),
        desc: zhIntl(
          '针对企业固定/共享工位、部门/项目组工位、创业空间/园区工位等可视化及分类管理。',
        ),
      },
      {
        title: zhIntl('“融合”解决方案'),
        desc: zhIntl(
          '通过增加采集和整理可提供数据与分析和流程自动化之间的互联网络使得实现真正的智能成为可能。提供跨越租赁、成本计算、空间管理资产维护、环境和能源管理等元素——并将所有元素合并计算于单一技术平台的解决方案。',
        ),
      },
      {
        title: zhIntl('平台的解决方案'),
        desc: zhIntl(
          '具备地图上搜索位置和预订方式，更加直观找人找位置；移动应用随手携带，随心所用。',
        ),
      },
      {
        title: zhIntl('管理系统结构'),
        desc: zhIntl(
          '提供具有高度可视性、可控性和自动化，并跨功能的能力，以及“至繁归于至简”的管理系统结构。',
        ),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/sws/book.png'),
        title: zhIntl('多种预订方式'),
        desc: zhIntl('浏览器，APP，随时随查看和预订工位'),
      },
      {
        icon: require('@/assets/product/sws/apply.png'),
        title: zhIntl('工位申请与审批'),
        desc: zhIntl('让工位使用更加系统化，彰显企业风采。'),
      },
      {
        icon: require('@/assets/product/sws/move.png'),
        title: zhIntl('工位搬迁'),
        desc: zhIntl(
          '采用任务式设立部门工位搬迁，协助行政管理人员处理日常面对的部门工位批量调整和更换工作。',
        ),
      },
      {
        icon: require('@/assets/product/sws/check.png'),
        title: zhIntl('工位盘点'),
        desc: zhIntl('自动识别身份证件，大幅度提高等级效率，保证信息准确性。'),
      },
      {
        icon: require('@/assets/product/sws/map.png'),
        title: zhIntl('地图管理'),
        desc: zhIntl('采用全仿真楼层平面图方式，进行资源业务状态和位置呈现。'),
      },
      {
        icon: require('@/assets/product/sws/device.png'),
        title: zhIntl('设施管理'),
        desc: zhIntl('针对不同座位的家具特性，可进行叠加配置；方便使用者快速找到心仪的工作空间。'),
      },
      {
        icon: require('@/assets/product/sws/source.png'),
        title: zhIntl('资源管理'),
        desc: zhIntl('全面透视资源位置和使用现状，灵活查询报表，助力决策。 '),
      },
      {
        icon: require('@/assets/product/sws/data.png'),
        title: zhIntl('数据统计'),
        desc: zhIntl('为提升空间资源不同管理者的运营需求和工作习惯，提供弹性多维度统计分析报表。'),
      },
    ],
  },
  fm: {
    productName: zhIntl('设备设施管理'),
    banner:
      getLocale() === 'en-US'
        ? require('@/assets/product/fm/bannerEn.png')
        : require('@/assets/product/fm/banner.png'),
    bg: require('@/assets/product/fm/bg.png'),
    title: zhIntl('智慧化设施管理平台'),
    subTitle: zhIntl(
      '强大的设备集成对接能力和数据应用管理能力,通过数据中通和IOT平台  实现对企业设备运行状态的可视化管理,形成设备状态检测、异常报警、自定义维护的闭环管理流程',
    ),
    featureTitle: zhIntl('智慧化设施管理平台'),
    list: [
      {
        title: formatMessage({ id: 'product.introduc.fm.subTitle1' }),
        desc: formatMessage({ id: 'product.introduc.fm.subTitle1.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.fm.subTitle2' }),
        desc: formatMessage({ id: 'product.introduc.fm.subTitle2.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.fm.subTitle3' }),
        desc: formatMessage({ id: 'product.introduc.fm.subTitle3.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.fm.subTitle4' }),
        desc: formatMessage({ id: 'product.introduc.fm.subTitle4.desc' }),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/fm/project.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage1' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage1.desc' }),
      },
      {
        icon: require('@/assets/product/fm/device.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage2' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage2.desc' }),
      },
      {
        icon: require('@/assets/product/fm/service.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage3' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage3.desc' }),
      },
      {
        icon: require('@/assets/product/fm/worker.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage4' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage4.desc' }),
      },
      {
        icon: require('@/assets/product/fm/check.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage5' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage5.desc' }),
      },
      {
        icon: require('@/assets/product/fm/oms.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage6' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage6.desc' }),
      },
      {
        icon: require('@/assets/product/fm/know.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage7' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage7.desc' }),
      },
      {
        icon: require('@/assets/product/fm/online.png'),
        title: formatMessage({ id: 'product.introduc.fm.advantage8' }),
        desc: formatMessage({ id: 'product.introduc.fm.advantage8.desc' }),
      },
    ],
  },
  esg: {
    productName: zhIntl('环境能碳管理'),
    banner:
      getLocale() === 'en-US'
        ? require('@/assets/product/esg/bannerEn.png')
        : require('@/assets/product/esg/banner.png'),
    bg: require('@/assets/product/esg/bg.png'),
    title: zhIntl('环境能碳管理系统'),
    subTitle: zhIntl(
      '广泛快速的自动采集，精准分析和智能优化，推进企业数字化转型，促进企业ESG目标，响应国家双碳战略',
    ),
    featureTitle: zhIntl('环境能碳管理系统'),
    list: [
      {
        title: formatMessage({ id: 'product.introduc.esg.subTitle1' }),
        desc: formatMessage({ id: 'product.introduc.esg.subTitle1.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.esg.subTitle2' }),
        desc: formatMessage({ id: 'product.introduc.esg.subTitle2.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.esg.subTitle3' }),
        desc: formatMessage({ id: 'product.introduc.esg.subTitle3.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.esg.subTitle4' }),
        desc: formatMessage({ id: 'product.introduc.esg.subTitle4.desc' }),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/esg/help.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.esg.advantage1' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.esg.advantage1.desc' }),
      },
      {
        icon: require('@/assets/product/esg/promote.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.esg.advantage2' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.esg.advantage2.desc' }),
      },
      {
        icon: require('@/assets/product/esg/dongcha.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.esg.advantage3' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.esg.advantage3.desc' }),
      },
      {
        icon: require('@/assets/product/esg/gaoxiao.png'),
        title: formatMessage({ id: 'product.introduc.Cloud.esg.advantage4' }),
        desc: formatMessage({ id: 'product.introduc.Cloud.esg.advantage4.desc' }),
      },
    ],
  },
  bdp: {
    productName: zhIntl('企业数字洞察'),
    banner:
      getLocale() === 'en-US'
        ? require('@/assets/product/bdp/bannerEn.png')
        : require('@/assets/product/bdp/banner.png'),
    bg: require('@/assets/product/bdp/bg.png'),
    title: zhIntl('威思客企业数字洞察'),
    subTitle: zhIntl(
      '完美融合企业行政与智能物联大数据，通过信息发布、互动大屏和数据洞察，实现空间的可视化展示与智能信息发布。助力企业优化空间体验，提升资源利用效率，支持企业科学决策',
    ),
    featureTitle: zhIntl('企业数字洞察'),
    list: [
      {
        title: formatMessage({ id: 'product.introduc.bdp.subTitle1' }),
        desc: formatMessage({ id: 'product.introduc.bdp.subTitle1.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.bdp.subTitle2' }),
        desc: formatMessage({ id: 'product.introduc.bdp.subTitle2.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.bdp.subTitle3' }),
        desc: formatMessage({ id: 'product.introduc.bdp.subTitle3.desc' }),
      },
      {
        title: formatMessage({ id: 'product.introduc.bdp.subTitle4' }),
        desc: formatMessage({ id: 'product.introduc.bdp.subTitle4.desc' }),
      },
    ],
    feature: [
      {
        icon: require('@/assets/product/bdp/info.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage1' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage1.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/screen.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage2' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage2.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/cost.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage3' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage3.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/resource.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage4' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage4.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/user.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage5' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage5.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/healthy.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage6' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage6.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/synthesis.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage7' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage7.desc' }),
      },
      {
        icon: require('@/assets/product/bdp/view.png'),
        title: formatMessage({ id: 'product.introduc.bdp.advantage8' }),
        desc: formatMessage({ id: 'product.introduc.bdp.advantage8.desc' }),
      },
    ],
  },
};

const Page = ({
  history: {
    location: {
      query: { pdtype = 'vst' },
    },
  },
}) => {
  const [productCode, setProductCode] = useState('vst');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    setProductCode(pdtype);
    setTimeout(() => {
      setShow(false);
    }, 200);
  }, [pdtype]);

  return !show ? (
    <div className={styles.product}>
      <div className={styles.bg}>
        <img src={productList?.[productCode]?.bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>
                {zhIntl(productList?.[productCode]?.productName)}
              </div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt; <span>{zhIntl('产品')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>
                  {zhIntl(productList?.[productCode]?.productName)}
                </span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      <div className={styles.info}>
        <Fade bottom cascade>
          <div className={styles.title}>
            <div>{productList?.[productCode]?.title}</div>
          </div>
        </Fade>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>{productList?.[productCode]?.subTitle}</div>
        </LightSpeed>
      </div>
      <div className={styles.advantage}>
        <Slide left>
          <div className={`${styles.advantageList} ${styles.imgArea}`}>
            <img src={productList?.[productCode]?.banner} alt="" />
            <Button
              style={{
                width: 160,
                height: 60,
                borderRadius: 27,
                margin: '0 auto',
                fontSize: 16,
                fontWeight: 600,
              }}
              type="primary"
              onClick={() => history.push('/register')}
            >
              {zhIntl('免费体验')}
            </Button>
          </div>
        </Slide>
        <Slide right>
          <div className={styles.advantageList}>
            {productList?.[productCode]?.list?.map((item) => (
              <div className={styles.advantageItem} key={item.title}>
                <div className={styles.listTitle}>
                  <img src={check} style={{ marginRight: 14, marginTop: -4 }} width={22} alt="" />
                  <span>{item.title}</span>
                </div>
                <div className={styles.listDesc}>{item.desc}</div>
              </div>
            ))}
          </div>
        </Slide>
      </div>

      <div className={styles.feature}>
        <div className={styles.featureTitle}>{productList?.[productCode]?.featureTitle}</div>
        <div className={styles.featureList}>
          {productList?.[productCode]?.feature?.map((item) => (
            <div className={styles.featureItem} key={item.title}>
              <img src={item.icon} alt="" width={100} />
              <div className={styles.itemTitle}>{item.title}</div>
              <div className={styles.itemDesc}>{item.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default connect((state) => state)(Page);
