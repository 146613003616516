export default {
  'index.title': '威思客云，为企业智慧办公赋能',
  'index.title.tip':
    '致力于将企业的办公场景的全过程数字化、智能化，构建云时代的办公场景，助力高效办公。',
  'index.visitor': '智能访客',
  'index.visitor.tip': '智能，安全的场景化云服务，助力访客体验升级',
  'index.meeting': '智能会议',
  'index.meeting.tip': '随时随地预约会议资源，带给客户智能高效的会议体验',
  'index.space': '空间管理',
  'index.space.tip': '可视化IOT集成平台，一站式智能化整体解决方案',
  'index.fm': '设备设施管理',
  'index.fm.tip': '线上化、流程化、数据化、智能化、智慧化的建筑设施综合运维管理平台',
  'index.esg': '环境能碳管理',
  'index.esg.tip':
    '以"碳中和，碳达峰"为目标，通过对多种设备进行综合监管，以数字化手段为企业节能、增效',
  'index.bdp': '企业数字洞察',
  'index.bdp.tip': '一站式智能数据平台，以大数据驱动企业决策，优化资源，提升效率',
  'index.experience': '立即体验',
  'index.coming': '即将上线',
  'index.advantage': '我们的优势',
  'index.case': '客户案例',
  'index.advantage.name1': '产品更专注',
  'index.advantage.name2': '数据更安全',
  'index.advantage.name3': '企业成本更低',
  'index.advantage.name4': '服务更放心',
  'index.advantage.desc1': '十余年专注办公场景，千余家企业信任，助力企业智能办公',
  'index.advantage.desc2':
    '威思客云携手阿里云，提供严格的数据安全服务，为您的数据隐私和安全提供保障',
  'index.advantage.desc3':
    '按需购买，即买即用，不再需要购买服务器，也不用考虑安装和维护，系统自动升级',
  'index.advantage.desc4': '提供及时专业的技术支持，包括在线客服、在线反馈，1V1顾问式服务',

  'index.desc.tip1': '使用全球广受好评的',
  'index.desc.tip2': '威思客云平台',
  'index.desc.tip3': '为',
  'index.desc.tip4': '企业智慧办公',
  'index.desc.tip5': '赋能',
  'index.desc.tip6':
    '致力于将企业的办公场景的全过程数字化、智能化,构建云时代的办公场景，助力高效办公。',
  // 'index.desc.tip7': '',
  'index.desc.tip8': '成本节约',
  'index.desc.tip9':
    '通过对会议室、工位、前台、网络、音视频设备、门禁等统一智能化管理，降低企业运营、管理成本',
  'index.desc.tip10': '简单便捷',
  'index.desc.tip11':
    '统一平台化管理，多终端设备支持，多应用系统集成，后台 管理与员工使用体验更加简单、便捷',
  'index.desc.tip12': '高效安全',
  'index.desc.tip13':
    '端到端数据加密传输，完善安全认证机制，基于角色应用授 权管理，保障企业应用和数据安全',
  'index.desc.tip14': '详情请拨打咨询热线或立即申请免费试用',

  'index.hotline.1': '销售热线: (全球)',
  'index.hotline.2': '服务热线: (全球)',
  'index.vst.subtitle':
    '洞见现代访客场景，聚焦智能企业未来，全新的平台运维规划，稳定的云服务支持，高效的访客接待流程',
  'index.smt.subtitle':
    '全场景、全生命周期的会议解决方案，为企业带来智能、高效、简单、轻松的新型会议室预订管理体验',
  'index.sws.subtitle':
    '助企业针对固定座位和共享座位进行精细化分类管理，配合流程平台实现自动化联动处理，简化多平台手工操作，携手智能硬件，实现基于物联网的智能楼宇空间管控',
  'index.fm.subtitle':
    '强大的设备集成对接能力和数据应用管理能力,通过数据中通和IOT平台  实现对企业设备运行状态的可视化管理,形成设备状态检测、异常报警、自定义维护的闭环管理流程',
  'index.product.price': '产品定价',
  'index.product.price.unit.year': '年付',
  'index.product.dowload.tip1': '扫二维码，下载最新威思客云客户端',
  'index.product.dowload.tip2': '或者在 Apple App Store 中， 搜索 “威思客云”，进行下载',
  'index.product.dowload.tip3': '扫二维码，下载最新智能访客客户端',
  'index.product.dowload.tip4': '扫二维码，下载最新设备设施管理客户端',
  'index.case.subtitle':
    '客户覆盖《财富》500强60%以上的大型企业及多家中小型企业遍及互联网、金融、教育、医疗等多个行业',
  'index.help.catalogue': '目录',

  'index.station.china': '中国站',
  'index.station.intl': '国际站',

  'cloud.country.areacode.china': '(中国)',
  'cloud.country.areacode.hk': '(中国香港)',
  'cloud.country.areacode.korea': '(韩国)',
  'cloud.country.areacode.singapore': '(新加坡)',
  'cloud.country.areacode.thailand': '(泰国)',
  'cloud.country.areacode.usa': '(美国)',
};
