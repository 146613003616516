// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/home/jenkins/workspace/testcloud96/web.home/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.js').default,
    "routes": [
      {
        "path": "/about",
        "exact": true,
        "component": require('@/pages/about/index.js').default
      },
      {
        "path": "/case",
        "exact": true,
        "component": require('@/pages/case/index.js').default
      },
      {
        "path": "/download",
        "exact": true,
        "component": require('@/pages/download/index.js').default
      },
      {
        "path": "/hardware",
        "exact": true,
        "component": require('@/pages/hardware/index.js').default
      },
      {
        "path": "/hardware/robot",
        "exact": true,
        "component": require('@/pages/hardware/robot.js').default
      },
      {
        "path": "/help/helpData",
        "exact": true,
        "component": require('@/pages/help/helpData.js').default
      },
      {
        "path": "/help",
        "exact": true,
        "component": require('@/pages/help/index.js').default
      },
      {
        "path": "/index.html",
        "exact": true,
        "component": require('@/pages/index.js').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.js').default
      },
      {
        "path": "/login/EditPwd",
        "exact": true,
        "component": require('@/pages/login/EditPwd.js').default
      },
      {
        "path": "/login/active",
        "exact": true,
        "component": require('@/pages/login/active.js').default
      },
      {
        "path": "/login/find",
        "exact": true,
        "component": require('@/pages/login/find.js').default
      },
      {
        "path": "/login",
        "exact": true,
        "component": require('@/pages/login/index.js').default
      },
      {
        "path": "/passwd",
        "exact": true,
        "component": require('@/pages/passwd/index.js').default
      },
      {
        "path": "/product",
        "exact": true,
        "component": require('@/pages/product/index.js').default
      },
      {
        "path": "/product/price",
        "exact": true,
        "component": require('@/pages/product/price/index.js').default
      },
      {
        "path": "/register",
        "exact": true,
        "component": require('@/pages/register/index.js').default
      },
      {
        "path": "/service",
        "exact": true,
        "component": require('@/pages/service/index.js').default
      },
      {
        "path": "/sms",
        "exact": true,
        "component": require('@/pages/sms/index.js').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
