export default {
  'login.title': 'Sign In',
  'login.title1': 'Sign In',
  'login.account': 'Email/Mobile',
  'login.account.tip': 'Please enter email/mobile number',
  'login.account.regex.err': 'The email/mobile number format is incorrect',
  'login.account.regex.max': 'The maximum length of the mailbox is 50 characters',
  'login.password': 'Password',
  'login.password.forget': 'Forgot Password',
  'login.password.tip': 'Please enter the password',
  'login.password.regex':
    'The password must be at least 8 characters long and must contain letters and numbers',
  'login.register': 'Register',
  'login.register.free': 'Free Register',
  'login.active': 'Activation',
  'login.third': 'Third party sign in',
  'login.micro365': 'Microsoft 365',
  'login.feishu': 'Feishu',
  'login.password.find': 'Retrieve Password',
  'login.email': 'Email',
  'login.email.yours': 'Your Email',
  'login.email.tip': 'Please input your email',
  'login.email.regex.max': 'The maximum length of the mailbox is 50 characters',
  'login.email.regex.err': 'E-mail format is incorrect',
  'login.verify.code': 'Verification Code',
  'login.verify.imgcode.tip': 'Please enter the graphic verification code',
  'login.verify.code.tip': 'Please enter verification code',
  'login.verify.code.err': 'Incorrect verification code',
  'login.verify.resend': 's Resend',
  'login.verify.get': 'Get verify code',
  'login.change': 'Change',
  'login.email.send': 'Send Email',
  'login.direct': 'Sign In',
  'login.next': 'Next Step',
  'login.company.tip': 'Please confirm the joining company',
  'login.company.name': 'Company Name',
  'login.company.select': 'Please select a company',

  'login.password.init': 'Initialize Password',
  'login.password.init.tip': 'Please initialize your password',
  'login.password.sure': 'Confirm Password',
  'login.password.sure.tip': 'Please confirm your password',
  'login.password.wrong': 'The two passwords are inconsistent',

  'login.complete': 'Finish',

  'login.email.sent': 'Message has been sent',
  'login.email.sent.tip': 'The mail has been sent to your mailbox, please check it',
  'login.active.success': 'Activated successfully',
  'login.active.failed': 'Failed to activate user',

  'common.operate.slider.safe': 'Please complete security verification!',
  'common.operate.slider.barText': 'Slide right to fill the puzzle!',
  'common.operate.slider.loadingText': 'Loading ...!',
  'common.operate.slider.failedText': 'Try Again',
  'common.operate.slider.success': 'Verification succeeded!',

  'login.resetpwd.title': 'Set your login password',
  'login.resetpwd.rule.title': 'Please enter a new password and meet the following rules:',
  'login.resetpwd.rule1': '8 characters',
  'login.resetpwd.rule2': '1 letter',
  'login.resetpwd.rule3': '1 number',
  'login.resetpwd.rule.err1': 'The password format is incorrect',
  'login.resetpwd.rule.err2': 'The two passwords do not match',
  'login.resetpwd.enter1': 'Please enter your new password',
  'login.resetpwd.enter2': 'Please enter new password again',
  'login.resetpwd.submit': 'Submit',
  'login.resetpwd.success.title': 'Password reset successful',
  'login.resetpwd.success.tip1': 'automatically jump to the login page after，',
  'login.resetpwd.success.tip2': 'If do not automatically jump, please click below',
  'login.resetpwd.backlogin': 'Back to login page',
  'login.resetpwd.success': 'Password reset success!',

  'login.error.unknow': 'An unknown error occurred. Please try again later',
  'login.error.auth': 'If the authentication is abnormal, contact the administrator',
  'login.captcha.expired': 'Verification code expired',
  'login.captcha.error': 'Verification code error',
  'login.user.not.exist': 'Without this user',

  'login.prompt': 'Prompt',
  'login.init.success.tip': 'Initialization is successful, please login again',
  'login.register.success.tip': 'Registration successful, please login',
  'login.ok': 'OK',
  'login.register.success': 'Registered Successfully',
  'login.bind.success': 'Binding Successful',

  'login.editpwd.title.expired': 'Password expired, please change',
  'login.editpwd.title.first': 'Please change your password for the first time',
  'login.editpwd.pwd1': 'Original Password',
  'login.editpwd.pwd2': 'New Password',
  'login.editpwd.pwd3': 'Confirm Password',
  'login.editpwd.pwd1.tip': 'Please enter the original password',
  'login.editpwd.pwd2.tip': 'Please enter the new password',
  'login.editpwd.pwd3.tip': 'Please enter the new password again',
  'login.editpwd.rule.tip1': 'Please enter an 8-16 digit password',
  'login.editpwd.rule.tip2':
    'Must contain numbers and letters, can contain the following special characters:.! @ # $% ^ & * - _',
  'login.editpwd.success': 'Modified Successfully',

  'login.security.code.tip': 'Enter the security code',
  'login.security.step': 'Fetch Step',
  'login.security.step1':
    '1. Open the 2FA app on your device (phone, tablet, computer, etc.), recommended',
  'login.security.step2': '2. Find the account you scanned and tied earlier',
  'login.security.step3':
    '3. Get the password code displayed and enter it in the input field on the left',

  'cloud.safe.verify.success': 'Verified Successfully',
  'cloud.safe.verify.failure': 'Verified Failed',
  'cloud.safe.verify.invalid': 'The verification code is invalid, please get it again',

  'login.back.home': 'Back Homepage',

  'menu.select.default.2FA.save.code':
    'Security Code (Please make sure your phone time is accurate)',
  'menu.select.default.2FA.save.code.placeholder': 'Please enter the security code ',
  'login.security.use.type': 'How to use',
  a389f968_b8f4_4b1b_8da1_28e41420456e:
    '1. Download the application that supports the FFA function',
  '9e192288_849e_4721_bca9_9b9d4d801edd':
    '2. Open the QR code at the top of the application scan and enter the security code at the bottom to enable FFA authentication',
  '3f3564ad_aa31_4523_8bf1_7b12ccb30496':
    '3. The next time you login, fill in the security code displayed in your app to login safely',
  '6af3d87f_23ba_4fd6_ad6f_19a8c40ed582': 'Binding FFA Authentication',
};
