export default {
  'login.title': '登 錄',
  'login.title1': '登錄',
  'login.account': '郵箱/手機號',
  'login.account.tip': '請輸入郵箱/手機號',
  'login.account.regex.err': '郵箱/手機號格式不正確',
  'login.account.regex.max': '郵箱最大長度為50個字符',
  'login.password': '密碼',
  'login.password.forget': '忘記密碼',
  'login.password.tip': '請輸入密碼',
  'login.password.regex': '密碼長度不少于8位，且必須包含字母和數字',
  'login.register': '註冊',
  'login.register.free': '免費註冊',
  'login.active': '激活',
  'login.third': '第三方登錄',
  'login.micro365': 'Microsoft 365',
  'login.feishu': '飛書',
  'login.password.find': '找回密碼',
  'login.email': '郵箱',
  'login.email.yours': '您的郵箱',
  'login.email.tip': '請輸入郵箱',
  'login.email.regex.max': '郵箱最大長度為50個字符',
  'login.email.regex.err': '郵箱格式不正確',
  'login.verify.code': '驗證碼',
  'login.verify.imgcode.tip': '請輸入圖形驗證碼',
  'login.verify.code.tip': '請輸入驗證碼',
  'login.verify.code.err': '驗證碼不正確',
  'login.verify.resend': 's後可重發',
  'login.verify.get': '獲取驗證碼',
  'login.change': '換一張',
  'login.email.send': '發送郵件',
  'login.direct': '直接登錄',
  'login.next': '下一步',
  'login.company.tip': '請確認加入的公司',
  'login.company.name': '公司名',
  'login.company.select': '請選擇公司',

  'login.password.init': '初始化密碼',
  'login.password.init.tip': '請初始化您的密碼',
  'login.password.sure': '確認密碼',
  'login.password.sure.tip': '請確認密碼',
  'login.password.wrong': '兩次密碼不一致',

  'login.complete': '完成',

  'login.email.sent': '郵件已發送',
  'login.email.sent.tip': '郵件已發送至您的郵箱，快去查收郵件吧',
  'login.active.success': '激活成功',
  'login.active.failed': '激活用戶失敗',

  'common.operate.slider.safe': '請完成安全驗證!',
  'common.operate.slider.barText': '向右滑動填充拼圖!',
  'common.operate.slider.loadingText': '正在加載中...!',
  'common.operate.slider.failedText': '再試一次',
  'common.operate.slider.success': '驗證成功！',

  'login.resetpwd.title': '設置您的登錄密碼',
  'login.resetpwd.rule.title': '請輸入新密碼，並符合以下規則：',
  'login.resetpwd.rule1': '8個字符',
  'login.resetpwd.rule2': '1個字母',
  'login.resetpwd.rule3': '1個數字',
  'login.resetpwd.rule.err1': '密碼格式不正確',
  'login.resetpwd.rule.err2': '兩次密碼不一致',
  'login.resetpwd.enter1': '請輸入您的新密碼',
  'login.resetpwd.enter2': '請再次輸入新密碼',
  'login.resetpwd.submit': '提交',
  'login.resetpwd.success.title': '密碼重置成功',
  'login.resetpwd.success.tip1': '後自動跳轉登錄頁，',
  'login.resetpwd.success.tip2': '如系統沒有自動跳轉請點擊下方',
  'login.resetpwd.backlogin': '返回登錄頁',
  'login.resetpwd.success': '密碼修改成功',

  'login.error.unknow': '系統出現未知錯誤，請稍後再試',
  'login.error.auth': '認證異常，請聯系管理員',
  'login.captcha.expired': '驗證碼過期',
  'login.captcha.error': '驗證碼錯誤',
  'login.user.not.exist': '沒有此用戶',

  'login.prompt': '提示',
  'login.init.success.tip': '初始化成功，請重新登錄',
  'login.register.success.tip': '注冊成功，請登錄',
  'login.ok': '確定',
  'login.register.success': '注冊成功',
  'login.bind.success': '綁定成功',

  'login.editpwd.title.expired': '密碼過期，請修改密碼',
  'login.editpwd.title.first': '首次登錄，請修改密碼',
  'login.editpwd.pwd1': '原始密碼',
  'login.editpwd.pwd2': '新密碼',
  'login.editpwd.pwd3': '確認密碼',
  'login.editpwd.pwd1.tip': '請輸入原始密碼',
  'login.editpwd.pwd2.tip': '請輸入新密碼',
  'login.editpwd.pwd3.tip': '請再次輸入新密碼',
  'login.editpwd.rule.tip1': '請輸入8到16位密碼',
  'login.editpwd.rule.tip2': '必需包含數字及字母，可包含如下特殊字符：.!@#$%^&*-_',
  'login.editpwd.success': '修改成功',

  'login.security.code.tip': '請輸入安全碼',
  'login.security.step': '獲取步驟',
  'login.security.step1': '1. 打開您設備(手機、平板、電腦等)上的雙因素認證應用，推薦使用',
  'login.security.step2': '2. 找到您之前掃描並綁定的賬號',
  'login.security.step3': '3. 獲取顯示的密碼代碼，填入左側的輸入框',

  'cloud.safe.verify.success': '驗證成功',
  'cloud.safe.verify.failure': '驗證失敗',
  'cloud.safe.verify.invalid': '驗證碼已失效，請重新獲取',

  'login.back.home': '返回首頁',

  'menu.select.default.2FA.save.code': '安全碼（請確保您的手機時間準確）',
  'menu.select.default.2FA.save.code.placeholder': '請輸入安全碼',
  'login.security.use.type': '使用方式',
  "a389f968_b8f4_4b1b_8da1_28e41420456e": "1. 下載支持雙因素功能的應用，推薦使用",
  "9e192288_849e_4721_bca9_9b9d4d801edd": "2. 打開應用掃描上方的二維碼，然後在下方填入安全碼啓用雙重認證",
  "3f3564ad_aa31_4523_8bf1_7b12ccb30496": "3. 下次登錄時，填入您應用裏顯示的安全碼，即可安全登入",
  "6af3d87f_23ba_4fd6_ad6f_19a8c40ed582": "綁定雙重認證",
};
