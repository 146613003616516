export default {
  200: 'The server successfully returned the requested data.',
  201: 'Data is created or modified successfully.',
  202: 'A request has been queued in the background (asynchronous task).',
  204: 'Deleting data succeeded.',
  400: 'There was an error in the request, and the server did not create or modify data.',
  401: 'The user has no permissions (wrong token, username, password).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request was made for a non-existent record and the server did not act on it.',
  406: 'The requested format is not available.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred while creating an object.',
  426: 'User name or password is incorrect, please re-enter.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'The gateway is faulty.',
  503: 'Service unavailable, server temporarily overloaded or under maintenance.',
  504: 'The gateway times out.',
  '': 'Unknown Error',
  100000: 'System Exception',
  101000: 'Illegal Operation',
  101001: 'Incorrect Parameter',
  101002: 'Exit failed, token is empty',
  101003: 'Logout failed, token is invalid',
  101004: 'Operation failed, token is empty',
  101005: 'Operation fails, token format is invalid',
  101006: 'Operation fails, token is invalid',
  101007: 'Operation failed, refreshToken is empty',
  101008: 'Operation failed, refreshToken is invalid',
  101009: 'Password decryption failed',
  101010: 'The account format is incorrect',
  101011: 'Failed to obtain domain information through refreshToken',
  101012: 'Authentication mode is not supported',
  101013: 'User name or password is incorrect, please re-enter',
  101014: 'The client information in the request header is empty',
  101015: 'User search failure',
  101020: 'Failed to upload the license file.',
  101021: 'Uploading the license file fails.',
  101022: 'Updating and uploading the license file failed.',
  101023: 'The license device code verification fails',
  101024: 'Failed to verify the license validity period',
  101025: 'The license is invalid or does not exist',
  101026: 'The license function is disabled',
  101030: 'The verification code cannot be empty',
  101031: 'The verification code is invalid',
  101032: 'The verification code does not exist',
  101040: 'The signature or timestamp parameter does not exist',
  101041: 'The timestamp parameter format is incorrect',
  101042: 'Request Expired',
  101043: 'Invalid Signature',
  101044: 'The current user has no role. Contact the administrator',
  101052: 'Account Lock',
  102000: 'Illegal Operation',
  102001: 'Incorrect Parameter',
  102002: 'The tenant to which the department is added does not exist',
  102003:
    'If subdepartments or users exist in the current department, move them to other departments',
  102004: 'Failed to save department',
  102005: 'The menu contains subordinates that cannot be deleted',
  102006: 'If a user exists in the current role, transfer the user first',
  102007: 'Only platform administrators can modify common roles',
  102008: 'Tenant does not exist',
  102009: "Can't delete yourself",
  102010: 'The tenant corresponding to the added user does not exist',
  102011: 'Mailbox already exists',
  102012: 'The new password is different from the confirmed password',
  102013: 'Information does not exist',
  102014: 'Old password error',
  102015: 'Department does not exist',
  102016: 'This wechat has been bound',
  102017: 'This DingTalk has been bound',
  102018: 'Other binding methods other than wechat and DingTalk are not supported',
  102019: 'This user is not bound to a wechat account',
  102020: 'This user is not bound to a DingTalk account',
  102021: 'The Virsical token is invalid',
  102022: 'The current authentication type is not supported',
  102023: 'Multiple users are queried for non-secret authentication.',
  102024: 'Non-secret authentication, the user was not queried',
  102025: 'The mobile number already exists',
  102026: 'User does not exist',
  102027: 'The platform administrator role cannot be deleted',
  102030: 'This role identifier already exists',
  102040: 'This tenant domain already exists',
  102050: 'The tenant corresponding to the added space does not exist',
  102051: 'There is a subspace in the current space, please transfer first',
  102052: 'Save space failure',
  102053: 'This space is not bound to holidays',
  102054: 'This space does not exist',
  102055: 'The department administrator has not set this parameter',
  102056: 'The department administrator does not exist',
  102057: 'The current tenant has been disabled',
  102058: 'System Exception',
  102059: 'The name of a department must be unique',
  102060: 'This account already exists',
  102091: 'Tenant initialization failed to save tenants',
  102092: 'Tenant failed to initialize save department',
  102093: 'Tenant initialization failed to save department relationship',
  102094: 'Tenant initialization failed to save the user',
  102095: 'Tenant initialization failed to save user role relationship',
  102096: 'User information save exception',
  102097: 'User information is updated exception',
  102098: 'User role relationship preservation exception',
  102099: 'Saving user exceptions in bulk',
  102100: 'Save user role exceptions in bulk',
  102101: 'Attribute code does not exist',
  102106: 'The email account does not exist',
  102107: 'Invalid Captcha',
  102108: 'Failed to set password',
  102109: 'Invalid Captcha',
  102110: 'The new password is different from the confirmed password',
  100100: 'Successful',
  100101: 'Network Exception',
  100102: 'Parameter Exception',
  100103: 'No Access',
  100104: 'The number of personnel has reached the line',
  100105: 'The file cannot be empty',
  100106: 'Failed to obtain the organization list of basic services. Procedure',
  100107: 'The data exceeds 1000 rows',
  100108:
    'The number of imported personnel exceeds the number of remaining personnel that can be added',
  100109: 'Failed to save user information',
  100110: 'The import succeeds. If there are many import personnel, refresh it later...',
  100111: 'Email duplicate please modify',
  100112: 'Failed to obtain the enterprise package. ',
  100113: 'Failed to obtain users for basic services. ',
  100114: 'Export Failure',
  100115: 'No personnel data',
  100116: 'The verification code has expired or does not exist',
  100117: 'User does not exist',
  100118:
    'The user has already initialized the password, so there is no need to repeat this operation',
  102112: 'The user name or password is incorrect',
  101048: 'The account is locked for {lock} minutes after multiple authentication failures',
  1001001: 'Your package has expired',
  1001020: 'The current user is activated',
  1001023: 'The mailbox is already in use. Please change the mailbox',
  1001027: 'Invalid Captcha',
  1001028: 'The verification code is incorrect. Please enter the correct verification code',
  1001031: 'Parameter Error',
  1001062: 'Failed to activate user',
  1001083: 'The phone number has been registered',
  1001084: 'User does not exist',

  106000: 'System Exception',
  106526: 'The secret-free authentication failed.',
  102164: 'The phone number is not unique',
  102165: 'User does not exist',
  102151: 'The new password is the same as the old password',
  102182: 'Account not activated',
  102183: 'The phone number does not exist, please re-enter or verify the phone number first',
  102184: 'The mailbox does not exist, please re-enter or verify the mailbox first',

  1001002: 'The user account or verification code is empty',
  1001112: 'The SMS service is not configured',
  1001054: 'SMS service search failed',
  1001122: 'The login token has expired',
  1001124: 'Operation Failure',
  1001091: 'Account does not exist',
  1001125:
    'The amount of data exported to excel cannot exceed 5000.Please adjust the search criteria and export again',

  102125: 'The new password cannot be the same as the old password',
  102116: 'The password contains weak passwords and does not meet the strength requirements',

  101054: 'Please change your password for the first time',
  101055: 'Password expired, please change',
  1001025: 'License has expired',
  1001026: 'License number has reached the upper limit',
  1001147: 'The license limit has been exceeded, please open again or update the license',

  101079: 'Security code is empty',
  101080: 'Security code verification failed',
  106503: 'Failed to get application information!',
  106504: 'Authentication Failed!',
  106505: 'Wrong user name or password!',

  102181: 'Your phone number or email address has been verified and cannot be modified',
  102150: 'Password cannot include username, phone, email and email prefix',
};
