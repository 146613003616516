export default {
  'login.title': '登 录',
  'login.title1': '登录',
  'login.account': '邮箱/手机号',
  'login.account.tip': '请输入邮箱/手机号',
  'login.account.regex.err': '邮箱/手机号格式不正确',
  'login.account.regex.max': '邮箱最大长度为50个字符',
  'login.password': '密码',
  'login.password.forget': '忘记密码',
  'login.password.tip': '请输入密码',
  'login.password.regex': '密码长度不少于8位，且必须包含字母和数字',
  'login.register': '注册',
  'login.register.free': '免费注册',
  'login.active': '激活',
  'login.third': '第三方登录',
  'login.micro365': 'Microsoft 365',
  'login.feishu': '飞书',
  'login.password.find': '找回密码',
  'login.email': '邮箱',
  'login.email.yours': '您的邮箱',
  'login.email.tip': '请输入邮箱',
  'login.email.regex.max': '邮箱最大长度为50个字符',
  'login.email.regex.err': '邮箱格式不正确',
  'login.verify.code': '验证码',
  'login.verify.imgcode.tip': '请输入图形验证码',
  'login.verify.code.tip': '请输入验证码',
  'login.verify.code.err': '验证码不正确',
  'login.verify.resend': 's后可重发',
  'login.verify.get': '获取验证码',
  'login.change': '换一张',
  'login.email.send': '发送邮件',
  'login.direct': '直接登录',
  'login.next': '下一步',
  'login.company.tip': '请确认加入的公司',
  'login.company.name': '公司名',
  'login.company.select': '请选择公司',

  'login.password.init': '初始化密码',
  'login.password.init.tip': '请初始化您的密码',
  'login.password.sure': '确认密码',
  'login.password.sure.tip': '请确认密码',
  'login.password.wrong': '两次密码不一致',

  'login.complete': '完成',

  'login.email.sent': '邮件已发送',
  'login.email.sent.tip': '邮件已发送至您的邮箱，快去查收邮件吧',
  'login.active.success': '激活成功',
  'login.active.failed': '激活用户失败',

  'common.operate.slider.safe': '请完成安全验证!',
  'common.operate.slider.barText': '向右滑动填充拼图!',
  'common.operate.slider.loadingText': '正在加载中...!',
  'common.operate.slider.failedText': '再试一次',
  'common.operate.slider.success': '验证成功！',

  'login.resetpwd.title': '设置您的登录密码',
  'login.resetpwd.rule.title': '请输入新密码，并符合以下规则：',
  'login.resetpwd.rule1': '8个字符',
  'login.resetpwd.rule2': '1个字母',
  'login.resetpwd.rule3': '1个数字',
  'login.resetpwd.rule.err1': '密码格式不正确',
  'login.resetpwd.rule.err2': '两次密码不一致',
  'login.resetpwd.enter1': '请输入您的新密码',
  'login.resetpwd.enter2': '请再次输入新密码',
  'login.resetpwd.submit': '提交',
  'login.resetpwd.success.title': '密码重置成功',
  'login.resetpwd.success.tip1': '后自动跳转登录页，',
  'login.resetpwd.success.tip2': '如系统没有自动跳转请点击下方',
  'login.resetpwd.backlogin': '返回登录页',
  'login.resetpwd.success': '密码修改成功',

  'login.error.unknow': '系统出现未知错误，请稍后再试',
  'login.error.auth': '认证异常，请联系管理员',
  'login.captcha.expired': '验证码过期',
  'login.captcha.error': '验证码错误',
  'login.user.not.exist': '没有此用户',

  'login.prompt': '提示',
  'login.init.success.tip': '初始化成功，请重新登录',
  'login.register.success.tip': '注册成功，请登录',
  'login.ok': '确定',
  'login.register.success': '注册成功',
  'login.bind.success': '绑定成功',

  'login.editpwd.title.expired': '密码过期，请修改密码',
  'login.editpwd.title.first': '首次登录，请修改密码',
  'login.editpwd.pwd1': '原始密码',
  'login.editpwd.pwd2': '新密码',
  'login.editpwd.pwd3': '确认密码',
  'login.editpwd.pwd1.tip': '请输入原始密码',
  'login.editpwd.pwd2.tip': '请输入新密码',
  'login.editpwd.pwd3.tip': '请再次输入新密码',
  'login.editpwd.rule.tip1': '请输入8到16位密码',
  'login.editpwd.rule.tip2': '必需包含数字及字母，可包含如下特殊字符：.!@#$%^&*-_',
  'login.editpwd.success': '修改成功',

  'login.security.code.tip': '请输入安全码',
  'login.security.step': '获取步骤',
  'login.security.step1': '1. 打开您设备(手机、平板、电脑等)上的双因素认证应用，推荐使用',
  'login.security.step2': '2. 找到您之前扫描并绑定的账号',
  'login.security.step3': '3. 获取显示的密码代码，填入左侧的输入框',

  'cloud.safe.verify.success': '验证成功',
  'cloud.safe.verify.failure': '验证失败',
  'cloud.safe.verify.invalid': '验证码已失效，请重新获取',

  'login.back.home': '返回首页',

  'menu.select.default.2FA.save.code': '安全码（请确保您的手机时间准确）',
  'menu.select.default.2FA.save.code.placeholder': '请输入安全码',
  'login.security.use.type': '使用方式',
  "a389f968_b8f4_4b1b_8da1_28e41420456e": "1. 下载支持双因素功能的应用，推荐使用",
  "9e192288_849e_4721_bca9_9b9d4d801edd": "2. 打开应用扫描上方的二维码，然后在下方填入安全码启用双重认证",
  "3f3564ad_aa31_4523_8bf1_7b12ccb30496": "3. 下次登录时，填入您应用里显示的安全码，即可安全登入",
  "6af3d87f_23ba_4fd6_ad6f_19a8c40ed582": "绑定双重认证",
};
