import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 云服务认证
 * @param payload
 * */

export const cloudLogin = (params) => {
  const data = {
    ...params,
    login_type: 'mail',
  };
  delete data.Authorization;
  const form = new FormData();
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const k in data) {
    form.append(k, data[k]);
  }
  return request(`/${RESTFUL_PATH.auth}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: params?.Authorization,
      'vsk-auth': params?.Authorization,
    },
    data: form,
  });
};

export const loginOut = () => {
  return request(`/${RESTFUL_PATH.auth}/token/logout`);
};

export const clientAuth = ({ Authorization }) => {
  return request(`/${RESTFUL_PATH.auth}/oauth/token?grant_type=client_credentials`, {
    headers: {
      Authorization,
      'vsk-auth': Authorization,
    },
  });
};
// 校验销售品是否过期
export function detectionAuthority({ authorization }) {
  return request(`/${RESTFUL_PATH.cloudOms}/users/login/check?entryCode=user`, {
    headers: {
      Authorization: authorization,
    },
  });
}

export function checkVerifyCode({ randomStr, code }) {
  return request(
    `/${RESTFUL_PATH.upms}/user/${RESTFUL_PATH.auth}/captcha/check?randomStr=${randomStr}&code=${code}`,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  );
}

export function sendMailCheck(mail) {
  return request(`/${RESTFUL_PATH.cloudOms}/users/checkMail/${encodeURIComponent(mail)}`);
}

export function sendMail(data) {
  if (data.mail) {
    // eslint-disable-next-line no-param-reassign
    data.mail = data.mail.toLowerCase();
  }
  return request(`/${RESTFUL_PATH.upms}/user/forget-pwd/send-mail`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function checkReset(data) {
  return request(`/${RESTFUL_PATH.upms}/user/forget-pwd/check-code`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function resetPassword(data) {
  return request(`/${RESTFUL_PATH.upms}/user/forget-pwd/set-pwd`, {
    method: 'POST',
    body: JSON.stringify({
      newPwd: data.npassword,
      reNewPwd: data.rpassword,
      mail: data.account,
      verifyCode: data.verifyCode,
    }),
  });
}

export function checkPackage(userInfo) {
  return request(`/${RESTFUL_PATH.cloudOms}/account/checkTenantProductUseStatus`, {
    headers: {
      Authorization: `bearer ${userInfo.access_token}`,
    },
  });
}

export function sendMailCode(data) {
  if (data.email) {
    // eslint-disable-next-line no-param-reassign
    data.email = data.email.toLowerCase();
  }
  return request(`/${RESTFUL_PATH.cloudOms}/users/send/captcha`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function checkMailCode(data) {
  return request(`/${RESTFUL_PATH.cloudOms}/users/canActivatedTenant/list`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function activeUser(data) {
  return request(`/${RESTFUL_PATH.cloudOms}/users/activate`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function microsoftLogin() {
  return request(`/${RESTFUL_PATH.auth}/sso/authUrl/teams?terminal=main`);
}

export function feishuLogin() {
  return request(`/${RESTFUL_PATH.wdf}/feishu/sso/loginUrl`);
}

export function commonInitData() {
  return request(`/${RESTFUL_PATH.upms}/common/enc/data`);
}

// 首次登录修改密码
export async function updatePwdById(params) {
  return request(`/${RESTFUL_PATH.upms}/user/updatePwdById`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 密码过期后修改密码
export async function resetPwdById(params) {
  return request(`/${RESTFUL_PATH.upms}/user/updatePwdByIdExpire`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 双因素验证
export async function securityCodeCheck({ payload }) {
  return request(
    `/${RESTFUL_PATH.auth}/token/securityCodeCheck?securityCode=${
      payload.securityCode
    }&flag=${encodeURIComponent(payload.flag)}`,
  );
}

// 滑动验证
export async function getPicture(params) {
  return request(`/${RESTFUL_PATH.auth}/captcha/get`, {
    method: 'POST',
    data: JSON.stringify(params),
  });
}

export async function reqCheck(params) {
  return request(`/${RESTFUL_PATH.auth}/captcha/check`, {
    method: 'POST',
    data: JSON.stringify(params),
  });
}

// 获取系统配置
export const getSet = () => {
  return request(`/${RESTFUL_PATH.cloudOms}/common/sysParam`);
};

/**
 * 免密绑定查询双因素二维码
 *
 * @param payload 请求参数体
 */
export async function generateById(payload) {
  return request(`/${RESTFUL_PATH.admin}/user/totp/generateById?userId=${payload?.userId}`);
}

/**
 * 免密绑定双因素
 *
 * @param payload 请求参数体
 */
export async function setToTPBindLogin(payload) {
  return request(
    `/${RESTFUL_PATH.auth}/token/securityCodeBind?userId=${
      payload.userId
    }&totpString=${encodeURIComponent(payload.totpString)}&securityCode=${
      payload.securityCode
    }&flag=${payload.flag}`,
  );
}

// 忘记密码发送验证码
export const sendCaptcha = (data) => {
  return request(`/${RESTFUL_PATH.upms}/user/forget-pwd/sendCaptcha`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

// 获取手机区号
export function getAreaCode() {
  return request(`/${RESTFUL_PATH.upms}/common/area-code`);
}
