export default {
  // "download.locales":"",
  "download.locales.title":"威思客云",
  "download.locales.Scan":"扫一扫二维码，下载最新威思客云客户端",
  "download.locales.iPad":"还可以用iPad打开App Store， 搜索 “威思客云”，点击下载",
  "download.locales.Applet":"小程序",

  "download.locales.clickdown":"点击下载",
  "download.locales.title1":"威思客云APP",
  "download.locales.visitor.mini":"访客小程序",
  "download.locales.toilet.mini":"厕位小程序",
}
